import React from 'react'
import './Appdev.css'

export default function Appdev() {
  return (
    <div>

      <div className='web-page-1' data-aos="zoom-in-up" data-aos-duration="2000">
        <h1 className='web-head-1 container'>APP DEVELOPMENT</h1>
        <hr className='container' style={{color:'white'}}/>
      </div>

      <div className='web-page-2 container' data-aos="flip-up" data-aos-duration="2000">
        <h1 className='web-head-2'>Crafting Seamless <span style={{color:"#f4d45e"}}> Mobile Experiences</span></h1>
        <p className='web-para-2'>
        At Oakton Technologies, we specialize in transforming ideas into powerful, user-centric mobile
  applications. With a keen eye for innovation and a commitment to excellence, our mobile app
  development services are designed to elevate your digital presence. Explore how Oakton can turn
  your mobile app vision into reality.
        </p>
      </div>

      <div className='web-page-3 container' data-aos="slide-left" data-aos-duration="2000">
        <h1 className='web-head-3'>Custom Mobile App Development</h1>
        <div className='web-detail-3'>
        <div className='image-centre'><img className='web-img' src='/Images/Mobile/mobile1.svg'></img></div>
        <p className='web-para-3'>
        Unlock the full potential of your business with Oakton's custom mobile app development
  services. Whether you need an iOS, Android, or cross-platform solution, our team is
  dedicated to creating apps that cater to your unique goals and user requirements.
        </p>
        </div>
      </div>

      <div className='web-page-4 container' data-aos="slide-right" data-aos-duration="2000">
        <h1 className='web-head-3'>Native App Development</h1>
        <div className='web-detail-4'>
        <p className='web-para-3'>
        Oakton leverages the power of native app development to deliver high-performance
  applications that seamlessly integrate with the specific features of iOS and Android devices.
  Our native apps provide optimal user experiences, taking full advantage of platform
  capabilities.
        </p>
        <div className='image-centre'><img className='web-img' src='/Images/Mobile/mobile10.svg'></img></div>
        </div>
      </div>

      <div className='web-page-3 container' data-aos="zoom-in" data-aos-duration="2000">
        <h1 className='web-head-3'>Content Management Systems (CMS)</h1>
        <div className='web-detail-3'>
        <div className='image-centre'><img className='web-img' src='/Images/Mobile/mobile2.svg'></img></div>
        <p className='web-para-3'>
        A custom CMS allows businesses to manage and organize digital content, such as
  text, images, and multimedia, on their websites. It can be tailored to specific content
  creation and publishing needs.
        </p>
        </div>
      </div>

      <div className='web-page-4 container' data-aos="zoom-out" data-aos-duration="2000">
        <h1 className='web-head-3'>Cross-Platform App Development</h1>
        <div className='web-detail-4'>
        <p className='web-para-3'>
        Reach a broader audience with Oakton's cross-platform app development expertise. Our
  developers use frameworks like React Native and Flutter to create apps that run seamlessly
  across multiple platforms, saving time and resources without compromising quality.
        </p>
        <div className='image-centre'><img className='web-img' src='/Images/Mobile/mobile3.svg'></img></div>
        </div>
      </div>

      <div className='web-page-3 container' data-aos="flip-left" data-aos-duration="2000">
        <h1 className='web-head-3'>User-Centric Design</h1>
        <div className='web-detail-3'>
        <div className='image-centre'><img className='web-img' src='/Images/Mobile/mobile4.svg'></img></div>
        <p className='web-para-3'>
        At Oakton, we prioritize user experience. Our mobile app designs are rooted in a deep
  understanding of user behaviors and preferences. From intuitive navigation to visually
  appealing interfaces, we ensure your app engages and delights users at every touchpoint.
        </p>
        </div>
      </div>

      <div className='web-page-4 container' data-aos="flip-right" data-aos-duration="2000">
        <h1 className='web-head-3'>Agile Development Methodology</h1>
        <div className='web-detail-4'>
        <p className='web-para-3'>
        Embrace flexibility and responsiveness with Oakton's agile development methodology. We
  adapt to changing requirements, incorporate feedback, and deliver incremental updates,
  ensuring your mobile app aligns perfectly with your evolving business needs.
        </p>
        <div className='image-centre'><img className='web-img' src='/Images/Mobile/mobile5.svg'></img></div>
        </div>
      </div>

      <div className='web-page-3 container' data-aos="flip-left" data-aos-duration="2000">
        <h1 className='web-head-3'>Enterprise Mobile Solutions</h1>
        <div className='web-detail-3'>
        <div className='image-centre'><img className='web-img' src='/Images/Mobile/mobile6.svg'></img></div>
        <p className='web-para-3'>
        Empower your workforce and enhance productivity with Oakton's enterprise mobile
  solutions. From employee-facing apps to customer engagement tools, we tailor mobile
  applications to address the unique challenges and opportunities within your organization.
        </p>
        </div>
      </div>

      <div className='web-page-4 container' data-aos="flip-right" data-aos-duration="2000">
        <h1 className='web-head-3'>App Maintenance and Support</h1>
        <div className='web-detail-4'>
        <p className='web-para-3'>
        Beyond development, Oakton provides ongoing maintenance and support for your mobile
  applications. Stay ahead with regular updates, performance monitoring, and responsive
  troubleshooting to ensure your app remains secure, efficient, and up-to-date.
        </p>
        <div className='image-centre'><img className='web-img' src='/Images/Mobile/mobile7.svg'></img></div>
        </div>
      </div>

      <div className='web-page-3 container' data-aos="flip-left" data-aos-duration="2000">
        <h1 className='web-head-3'>Integration with Emerging Technologies</h1>
        <div className='web-detail-3'>
        <div className='image-centre'><img className='web-img' src='/Images/Mobile/mobile8.svg'></img></div>
        <p className='web-para-3'>
        Stay on the cutting edge with Oakton's integration of emerging technologies. Whether it's
  incorporating AI, IoT, or AR into your mobile app, we ensure your digital solution stands out
  in a rapidly evolving tech landscape.
        </p>
        </div>
      </div>

      <div className='web-page-4 container' data-aos="flip-right" data-aos-duration="2000">
        <h1 className='web-head-3'>App Store Optimization (ASO)</h1>
        <div className='web-detail-4'>
        <p className='web-para-3'>
        Increase your app's visibility and downloads with Oakton's App Store Optimization services.
  We optimize app listings, keywords, and visuals to maximize your app's discoverability and
  reach a wider audience.
  Embark on a journey of mobile innovation with Oakton Technologies. Collaborate with us, and let's
  create mobile applications that captivate users, drive engagement, and propel your business forward.
  Contact us today to transform your mobile app ideas into a reality.
        </p>
        <div className='image-centre'><img className='web-img' src='/Images/Mobile/mobile9.svg'></img></div>
        </div>
      </div>

    </div>
  )
}
