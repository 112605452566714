import React from 'react'
import './Aboutcomponent.css'
import { Paper } from '@mui/material'

export default function Aboutcomponent() {

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  return (
    <>
      <div data-aos="zoom-in-up" data-aos-duration="2000" className='about-1 container'>
        <h1 className='head-about-1'>About Us</h1>
        <hr className='container' style={{color:"white"}}/>
      </div>

      <div data-aos="fade-right" data-aos-duration="2000" className='about-2 conatainer'>
        <img className='about-img-2' src='/Images/AboutSection.svg'/>
        <div className='about-detail-2 container'>
            <h1 className='head-about-2'>Where Ideas Take Flight - Elevate Your Online Experience with Our Services!</h1>
            <p className='para-about-2'>At Oakton Technologies, we are architects of innovation, crafting bespoke solutions to propel
businesses into the future. As a technology-driven company based in Aberdeen, United Kingdom, we
are passionate about empowering our clients through transformative digital experiences.</p>
            <div className='para-about-icons'>
                <div style={{display:"flex" , alignItems:"center"}}>
                  <div className='about-icon-2'>
                    <i class="fa-regular fa-thumbs-up"></i>
                  </div>
                  <p className="about-text-2">400+ five star rating!</p>
                </div>
                <div style={{display:"flex", alignItems:"center"}}>
                  <div className='about-icon-2'>
                    <i class="fa-solid fa-people-group"></i>
                  </div>
                  <p className="about-text-2">98% client retention!</p>
                </div>
            </div>
        </div>
      </div>

      {/* <Paper elevation={5} className='about-3 container'>
        <h1 className='about-head-3 container'>About Us</h1>
        <hr className='container'/>
        <div className='about-detail-3 container'>
          <p className='about-para-3'>
          Astream is your go-to destination for exceptional online web services. We are a team of passionate experts, dedicated to crafting cutting-edge solutions that empower businesses and individuals to succeed in the digital world.
          From web design and development to digital marketing and cloud hosting, we have the expertise and experience to make a meaningful impact on your online success. Let's collaborate and bring your digital version to life.
          </p>
        </div>
      </Paper> */}

      <div  className='about-4'>
         <img data-aos="fade-down" data-aos-duration="2000" src='/Images/discussAbout.jpg' className='about-img-4 container'/> 
         <div data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500" className='about-detail-4'>
            <Paper className='container about-detail-card-4' elevation={5}>
              <i class="fa-solid fa-paper-plane about-icon-4"></i>
              <hr/>
              <h2 className='about-head-4'>Journey of Transformation</h2>
              <p className='about-para-4'>Oakton Technologies invites you to embark on a journey of digital transformation. Whether you're
looking to streamline operations, enhance user experiences, or innovate in your industry, we are
here to be your trusted partner in technology.
Contact Oakton Technologies today, and let's explore the possibilities of transforming your ideas into
reality.</p>
            </Paper>
            <Paper className='container about-detail-card-4' elevation={5}>
              <i class="fa-solid fa-eye about-icon-4"></i>
              <hr/>
              <h2 className='about-head-4'>Our Vision</h2>
              <p className='about-para-4'>Oakton envisions a world where technology seamlessly integrates with business objectives, fostering
growth, efficiency, and unparalleled user experiences. We strive to be at the forefront of
technological advancements, translating innovation into tangible solutions that elevate the way
businesses operate.</p>
            </Paper>
         </div>
      </div>

      {/* <div className='about-5 container'>
        <h1 className='about-head-5'>Portfolio</h1>
        <p className='about-para-5'>Here we are storing some of our precious contributions for our customers.Have a look before choosing us.</p>
        <h2 className='about-head2-5'>OUR <span style={{color:"rgb(238, 155, 0)"}}>WORK HISTORY</span></h2>
        <Paper className='about-clients-5 container'>
            <div className='about-clients-title-5'>
              <h1 className='about-clients-head-5'>40+</h1>
              <p className='about-clients-para-5'>Years of Service</p>
            </div>

            <div className='about-clients-title-5'>
              <h1 className='about-clients-head-5'>US$ 18.55 Bn</h1>
              <p className='about-clients-para-5'>Total Revenue (LTM)</p>
            </div>

            <div className='about-clients-title-5'>
              <h1 className='about-clients-head-5'>56+</h1>
              <p className='about-clients-para-5'>Countries We Are Present In</p>
            </div>

            <div className='about-clients-title-5'>
              <h1 className='about-clients-head-5'>322K+</h1>
              <p className='about-clients-para-5'>Employees</p>
            </div>

            <div className='about-clients-title-5'>
              <h1 className='about-clients-head-5'>1,872</h1>
              <p className='about-clients-para-5'>Trusting Clients</p>
            </div>
        </Paper>
      </div> */}

      <Paper data-aos="flip-up" data-aos-duration="2000" className='about-6 container'>
          <i class="fa-solid fa-brain about-detail-img-6"></i>
          <hr/>
          <div className='about-detail-6'>
          <h1 className='about-head-6'>EXPERTISE THAT TRANSCEND BOUNDARIES</h1>
            <p className='about-detail-para-6'>
              With a foundation built on expertise and fueled by curiosity, Oakton Technologies brings a diverse set
              of skills to the table. From custom software development and web applications to managed cloud
              services and AI/ML solutions, our multidisciplinary approach is designed to meet the dynamic needs
              of today's businesses.
            </p>

          </div>
      </Paper>

      <Paper data-aos="flip-down" data-aos-duration="2000" className='about-7 container'>
          <i class="fa-solid fa-handshake about-detail-img-6"></i>
          <hr/>
          <div className='about-detail-6'>
          <h1 className='about-head-6'>COLLABORATION AT THE CORE</h1>
            <p className='about-detail-para-6'>
            We believe in the power of collaboration. At Oakton, we see our clients not just as partners but as
collaborators on a shared journey of growth. Our collaborative approach ensures that every solution
we craft is a true reflection of our clients' vision, goals, and unique business identity.
            </p>

          </div>
      </Paper>

      <Paper data-aos="flip-left" data-aos-duration="2000" className='about-8 container'>
          <i class="fa-solid fa-code-commit about-detail-img-6"></i>
          <hr/>
          <div className='about-detail-6'>
          <h1 className='about-head-6'>OUR COMMITMENT TO EXCELLENCE</h1>
            <p className='about-detail-para-6'>
            Excellence is not just a goal; it's a commitment ingrained in our culture. Oakton Technologies is
dedicated to delivering solutions that not only meet but exceed expectations. We adhere to the
highest standards of quality, security, and innovation, ensuring that our clients receive the best-inclass technology solutions.
            </p>

          </div>
      </Paper>

      <Paper data-aos="flip-right" data-aos-duration="2000" className='about-9 container'>
          <i class="fa-solid fa-people-arrows about-detail-img-6"></i>
          <hr/>
          <div className='about-detail-6'>
          <h1 className='about-head-6'>CUSTOMER-CENTRIC INNOVATION</h1>
            <p className='about-detail-para-6'>
            Understanding the unique needs of our clients is at the heart of what we do. Oakton Technologies
places a strong emphasis on customer-centric innovation. Whether it's creating intuitive user
interfaces, optimizing cloud infrastructure, or leveraging AI for business intelligence, our solutions
are meticulously crafted to enhance the experiences of end-users and drive business success.
            </p>

          </div>
      </Paper>

      <div  className='about-10 container'>
          <h1 className='about-head-6'>Why Choose Oakton Technologies ?</h1>
          <hr/>
          <div className='about-detail-10'>
            <Paper data-aos="flip-left" data-aos-duration="2000" elevation={5} className='about-detail-card'>
              <i class="fa-regular fa-lightbulb about-detail-img-6"></i>
              <hr className='container'></hr>
              <h1 className='about-head-10'>Innovation</h1>
              <p className='about-para-6'>We embrace the latest technologies to bring innovative solutions to the forefront,
ensuring our clients stay ahead in a rapidly evolving digital landscape.</p>
            </Paper>

            <Paper data-aos="flip-right" data-aos-duration="2000" elevation={5}  className='about-detail-card'>
              <i class="fa-regular fa-clock about-detail-img-6"></i>
              <hr className='container'></hr>
              <h1 className='about-head-10'>Flexibility</h1>
              <p className='about-para-6'>Our adaptive and flexible approach allows us to tailor solutions to the specific needs
of each client, ensuring a personalized and effective outcome.</p>
            </Paper>

            <Paper data-aos="flip-left" data-aos-duration="2000" elevation={5}  className='about-detail-card'>
              <i class="fa-solid fa-yin-yang about-detail-img-6"></i>
              <hr className='container'></hr>
              <h1 className='about-head-10'>Reliability</h1>
              <p className='about-para-6'>Oakton is synonymous with reliability. Clients trust us to deliver robust, secure, and
scalable solutions that stand the test of time.</p>
            </Paper>

            <Paper data-aos="flip-right" data-aos-duration="2000" elevation={5}  className='about-detail-card'>
              <i class="fa-solid fa-glasses about-detail-img-6"></i>
              <hr className='container'></hr>
              <h1 className='about-head-10'>Transparency</h1>
              <p className='about-para-6'>Open communication and transparency are cornerstones of our client relationships.
We keep our clients informed and engaged at every stage of the project.</p>
            </Paper>
          </div>
      </div>


    </>
  )
}
