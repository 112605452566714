import logo from './logo.svg';
import './App.css';

import { useEffect, useState } from 'react'
import Navbar from './Components/Navbar/Navbar';
import Footerpage from './Components/FooterPage/Footerpage';
import Preloader from './Components/Preloader/Preloader';
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Landingpage from './allComponents/Landingpage';
import DetailService from './allComponents/DetailService';
import DetailAbout from './allComponents/DetailAbout';
import DetailCarrer from './allComponents/DetailCarrer';
import DetailContact from './allComponents/DetailContact';
import Webdev from './allServices/WebDevelopment/Webdev';
import UIUXdev from './allServices/UIUXDevelopment/UIUXdev';
import Softwaredev from './allServices/SoftwareDevelopment/Softwaredev';
import Appdev from './allServices/AppDevelopment/Appdev';
import CloudServ from './allServices/CloudDevelopment/CloudServ';
import AIMLdev from './allServices/AIMLDevelopment/AIMLdev';
import AutoTech from './BlogComponent/Automate/AutoTech';
import InnoTech from './BlogComponent/Innovate/InnoTech';
import Tech from './BlogComponent/Technology/Tech';



function App() {

  const [isLoading , setIsLoading] = useState(true)

  useEffect(()=>{
    setTimeout(()=>{
      setIsLoading(false)
    },2000)
  },)

  return (
    <div className="App">
      { isLoading && <Preloader/> }
        
      { !isLoading &&
      <Router>
          <Navbar/> 

          <Routes>
              <Route path='/' element={<Landingpage/>}/>
          </Routes>

          <Routes>
              <Route path='/services' element={<DetailService/>} />
          </Routes>

          <Routes>
              <Route path='/aboutus' element={<DetailAbout/>} />
          </Routes>

          <Routes>
              <Route path='/carrers' element={<DetailCarrer/>} />
          </Routes>

          <Routes>
              <Route path='/contact' element={<DetailContact/>} />
          </Routes>

          <Routes>
              <Route path='/web' element={<Webdev/>} />
          </Routes>

          <Routes>
              <Route path='/uiux' element={<UIUXdev/>} />
          </Routes>

          <Routes>
              <Route path='/software' element={<Softwaredev/>} />
          </Routes>

          <Routes>
              <Route path='/mobile' element={<Appdev/>} />
          </Routes>

          <Routes>
              <Route path='/cloud' element={<CloudServ/>} />
          </Routes>

          <Routes>
              <Route path='/aiml' element={<AIMLdev/>} />
          </Routes>

          <Routes>
              <Route path='/automation' element={<AutoTech/>} />
          </Routes>

          <Routes>
              <Route path='/innovate' element={<InnoTech/>} />
          </Routes>

          <Routes>
              <Route path='/technology' element={<Tech/>} />
          </Routes>

          <Footerpage/>
      </Router>
      }

    </div>
  );

}

export default App;
