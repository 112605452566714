import React from 'react'
import Contactcomponent from '../Contactcomponent/Contactcomponent'

export default function DetailContact() {
  return (
    <div>
      <Contactcomponent/>
    </div>
  )
}
