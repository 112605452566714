import React from 'react'
import './Servicecomponent.css'
import { Paper } from '@mui/material'
import { Link } from 'react-router-dom'


export default function Servicecomponent() {
  return (
    <div className='service-component'>

      <div className='service-1 container' data-aos="zoom-in-up" data-aos-duration="2000">
        <h1 className='head-service'>Services</h1>
        <hr className='container' style={{color:"white"}}/>
      </div>

      <div className='service-2 container' data-aos="zoom-in-up" data-aos-duration="2000" >
        <h1 className='head-service-2'>WHAT WE <span style={{color:"#EE9B00"}} > OFFER ?</span></h1>
        <h3 className='head2-service-2'>Think beyond expectation.</h3>
        <p className='para-service-2'>Oakton Technologies offers a comprehensive suite of services designed to cater to every aspect of your digital and technological needs. From custom software development that tailors solutions to fit your unique business requirements, to specialized UI/UX design services that focus on creating intuitive and engaging user experiences, we cover all bases. Our services extend to data analysis and cybersecurity, ensuring not only the innovative functionality of your projects but also their security and reliability. With a commitment to quality and customer satisfaction, Oakton Technologies is your one-stop solution for all things tech, designed to propel your business forward in the digital age.</p>
        <div className='counts-service-2'>

          <div className='container-2' data-aos="flip-up" data-aos-duration="2000">
            <h1 style={{fontSize:"5vw"}}>500+</h1>
            <p>Happy clients</p>
          </div>

          <div className='container-2'data-aos="flip-up" data-aos-duration="2000">
          <h1 style={{fontSize:"5vw"}}>12+</h1>
          <p>Years of Experience</p>
          </div>

          <div className='container-2' data-aos="flip-up" data-aos-duration="2000">
            <h1 style={{fontSize:"5vw"}}>500+</h1>
            <p>Happy clients</p>
          </div>

        </div>
      </div>

      <div className='service-3'>
            
            <Paper data-aos="flip-up" data-aos-duration="2000" elevation={5} style={{backgroundColor:"rgb(224, 122, 95)"}} className='box-3 service-box-1'>
                      <h2 style={{textAlign:"left"}}>01</h2>
                      <hr className='hovercolor line'/>
                      <p>
                          <h3 className='innerText-Head' style={{textAlign:"left"}}>WEB DEVELOPMENT</h3> 
                          <p className='innerText-para'  style={{textAlign:"left"}}>
                            At Oakton Technologies, we are dedicated to revolutionizing the digital landscape through cuttingedge web app development. Our team of seasoned developers crafts dynamic and innovative web
applications tailored to meet the unique needs of your business. 
                          </p>
                          <Link to="/web" style={{textDecoration:"none"}}><button type="button" class="btn btn-outline service-btn-3" style={{marginTop:"5px" , marginLeft:"20px"  , display:"flex" , textAlign:"center" , marginLeft:"0%"}}>Know More...</button></Link>
                      </p>
            </Paper>

            <Paper data-aos="flip-up" data-aos-duration="2000" elevation={5} style={{backgroundColor:"rgb(129, 178, 154)"}} className='box-3 service-box-2'>
                      <h2 style={{textAlign:"left"}}>02</h2>
                      <hr className='hovercolor line'/>
                      <p>
                          <h3 className='innerText-Head' style={{textAlign:"left"}}>UX/UI DESIGN SERVICES</h3> 
                          <p className='innerText-para'  style={{textAlign:"left"}}>At Oakton Technologies, we understand that exceptional user experiences are the cornerstone of
successful digital products. Our UI/UX services are crafted to not only meet but exceed user
expectations, ensuring that every interaction leaves a lasting and positive impression. </p>
                      </p>
                      <Link to="/uiux" style={{textDecoration:"none"}}><button type="button" class="btn btn-outline service-btn-3" style={{marginTop:"5px" , marginLeft:"20px"  , display:"flex" , textAlign:"center" , marginLeft:"0%"}}>Know More...</button></Link>
            </Paper>

            <Paper data-aos="flip-up" data-aos-duration="2000" elevation={5} style={{backgroundColor:"rgb(152, 193, 217)"}} className='box-3 service-box-3'>
                      <h2 style={{textAlign:"left"}}>03</h2>
                      <hr className='hovercolor line'/>
                      <p>
                          <h3 className='innerText-Head' style={{textAlign:"left"}}>MOBILE APP DEVELOPMENT</h3> 
                          <p className='innerText-para'  style={{textAlign:"left"}}>At Oakton Technologies, we specialize in transforming ideas into powerful, user-centric mobile
applications. With a keen eye for innovation and a commitment to excellence, our mobile app
development services are designed to elevate your digital presence.</p>
                      </p>
                      <Link to="/mobile" style={{textDecoration:"none"}}><button type="button" class="btn btn-outline service-btn-3" style={{marginTop:"5px" , marginLeft:"20px"  , display:"flex" , textAlign:"center" , marginLeft:"0%"}}>Know More...</button></Link>
            </Paper>

            <Paper data-aos="flip-up" data-aos-duration="2000" elevation={5} style={{backgroundColor:"rgb(242, 204, 143)"}} className='box-3 service-box-4'>
                          <h2 style={{textAlign:"left"}}>04</h2>
                          <hr className='hovercolor line'/>
                          <p>
                              <h3 className='innerText-Head' style={{textAlign:"left"}}>SOFTWARE DEVELOPMENT</h3> 
                              <p className='innerText-para'  style={{textAlign:"left"}}>Custom software development involves creating tailored software solutions that address specific
needs and requirements of a particular business or organization</p>
                          </p>
                          <Link to="/software" style={{textDecoration:"none"}}><button type="button" class="btn btn-outline service-btn-3" style={{marginTop:"5px" , marginLeft:"20px"  , display:"flex" , textAlign:"center" , marginLeft:"0%"}}>Know More...</button></Link>
            </Paper>

            <Paper data-aos="flip-up" data-aos-duration="2000" elevation={5} style={{backgroundColor:"rgb(244, 241, 222)"}} className='box-3 service-box-5'>
                          <h2   style={{textAlign:"left"}}>05</h2>
                          <hr className='hovercolor line'/>
                          <p>
                              <h3 className='innerText-Head' style={{textAlign:"left"}}>MANAGED CLOUD SERVICES</h3> 
                              <p  className='innerText-para' style={{textAlign:"left"}}>In an era driven by digital transformation, harnessing the power of the cloud is essential for
businesses seeking agility, scalability, and efficiency. Oakton Technologies specializes in delivering
top-notch Managed Cloud Services, ensuring your organization maximizes the benefits of cloud
technology</p>
                          </p>
                          <Link to="/cloud" style={{textDecoration:"none"}}><button type="button" class="btn btn-outline service-btn-3" style={{marginTop:"5px" , marginLeft:"20px"  , display:"flex" , textAlign:"center" , marginLeft:"0%"}}>Know More...</button></Link>
            </Paper>

            <Paper data-aos="flip-up" data-aos-duration="2000" elevation={5} style={{backgroundColor:"rgb(255 242 179)"}} className='box-3 service-box-6'>
                          <h2 style={{textAlign:"left"}}>06</h2>
                          <hr className='hovercolor line'/>
                          <p>
                              <h3 className='innerText-Head' style={{textAlign:"left"}}>AI/ML SERVICES</h3> 
                              <p className='innerText-para' style={{textAlign:"left"}}>In the era of intelligent technology, harnessing the power of Artificial Intelligence (AI) and Machine
Learning (ML) is key to staying ahead in the digital landscape. At Oakton Technologies, we specialize
in delivering cutting-edge AI/ML services, empowering businesses to unlock new possibilities and
drive innovation.</p>
                          </p>
                          <Link to="/aiml" style={{textDecoration:"none"}}><button type="button" class="btn btn-outline service-btn-3" style={{marginTop:"5px" , marginLeft:"20px"  , display:"flex" , textAlign:"center" , marginLeft:"0%"}}>Know More...</button></Link>
            </Paper>
      </div>

    </div>
  )
}
