import React from 'react'
import './AIMLdev.css'

export default function AIMLdev() {
  return (
    <div>

    <div className='web-page-1' data-aos="zoom-in-up" data-aos-duration="2000">
      <h1 className='web-head-1 container'>AI/ML SERVICES</h1>
      <hr className='container' style={{color:'white'}}/>
    </div>

    <div className='web-page-2 container' data-aos="flip-up" data-aos-duration="2000">
      <h1 className='web-head-2'> Transforming Business with <span style={{color:"#f4d45e"}}> AI/ML Innovation</span></h1>
      <p className='web-para-2'>
      In the era of intelligent technology, harnessing the power of Artificial Intelligence (AI) and Machine
Learning (ML) is key to staying ahead in the digital landscape. At Oakton Technologies, we specialize
in delivering cutting-edge AI/ML services, empowering businesses to unlock new possibilities and
drive innovation. Explore how we can revolutionize your approach to data and intelligence.
      </p>
    </div>

    <div className='web-page-3 container' data-aos="slide-left" data-aos-duration="2000">
      <h1 className='web-head-3'>Custom AI/ML Solutions</h1>
      <div className='web-detail-3'>
      <div className='image-centre'><img className='web-img' src='/Images/AIML/aiml1.svg'></img></div>
      <p className='web-para-3'>
      Oakton Technologies takes pride in creating tailor-made AI/ML solutions to address the
unique challenges of your business. From predictive analytics to recommendation systems,
our custom solutions are designed to optimize processes and deliver actionable insights.
      </p>
      </div>
    </div>

    <div className='web-page-4 container' data-aos="slide-right" data-aos-duration="2000">
      <h1 className='web-head-3'>Predictive Analytics</h1>
      <div className='web-detail-4'>
      <p className='web-para-3'>
      Anticipate future trends and make informed decisions with Oakton's predictive analytics
solutions. We leverage advanced algorithms to analyze historical data, providing valuable
insights that guide strategic decision-making for your business.
      </p>
      <div className='image-centre'><img className='web-img' src='/Images/AIML/aiml2.svg'></img></div>
      </div>
    </div>

    <div className='web-page-3 container' data-aos="zoom-in" data-aos-duration="2000">
      <h1 className='web-head-3'>Natural Language Processing (NLP)</h1>
      <div className='web-detail-3'>
      <div className='image-centre'><img className='web-img' src='/Images/AIML/aiml3.svg'></img></div>
      <p className='web-para-3'>
      Enhance user experiences with NLP solutions from Oakton. Our expertise in natural language
processing enables your applications to understand, interpret, and generate human-like
language, opening up new possibilities for communication and interaction.
      </p>
      </div>
    </div>

    <div className='web-page-4 container' data-aos="zoom-out" data-aos-duration="2000">
      <h1 className='web-head-3'> Computer Vision</h1>
      <div className='web-detail-4'>
      <p className='web-para-3'>
      Revolutionize the way you perceive and interpret visual information with Oakton's computer
vision solutions. From image recognition to object detection, our applications enable
automated analysis and understanding of visual data.
      </p>
      <div className='image-centre'><img className='web-img' src='/Images/AIML/aiml4.svg'></img></div>
      </div>
    </div>

    <div className='web-page-3 container' data-aos="flip-left" data-aos-duration="2000">
      <h1 className='web-head-3'> Machine Learning Model Development</h1>
      <div className='web-detail-3'>
      <div className='image-centre'><img className='web-img' src='/Images/AIML/aiml5.svg'></img></div>
      <p className='web-para-3'>
      Oakton Technologies excels in the development of machine learning models. Our data
scientists work closely with your team to build robust models that learn from data patterns,
providing valuable insights and predictions for your business.
      </p>
      </div>
    </div>

    <div className='web-page-4 container' data-aos="flip-right" data-aos-duration="2000">
      <h1 className='web-head-3'>AI-powered Automation</h1>
      <div className='web-detail-4'>
      <p className='web-para-3'>
      Streamline processes and boost efficiency with Oakton's AI-powered automation solutions.
From routine tasks to complex workflows, we implement intelligent automation that reduces
manual effort and accelerates your business operations.
      </p>
      <div className='image-centre'><img className='web-img' src='/Images/AIML/aiml6.svg'></img></div>
      </div>
    </div>

    <div className='web-page-3 container' data-aos="flip-left" data-aos-duration="2000">
      <h1 className='web-head-3'>Personalized Recommendation Systems</h1>
      <div className='web-detail-3'>
      <div className='image-centre'><img className='web-img' src='/Images/AIML/aiml7.svg'></img></div>
      <p className='web-para-3'>
      Elevate user engagement and satisfaction with Oakton's personalized recommendation
systems. We create algorithms that analyze user behavior, preferences, and historical data to
deliver personalized content and suggestions.
      </p>
      </div>
    </div>

    <div className='web-page-4 container' data-aos="flip-right" data-aos-duration="2000">
      <h1 className='web-head-3'>Fraud Detection and Security</h1>
      <div className='web-detail-4'>
      <p className='web-para-3'>
      Safeguard your business with Oakton's AI-powered fraud detection and security solutions.
Our algorithms analyze patterns and anomalies, providing real-time insights to detect and
prevent fraudulent activities.
      </p>
      <div className='image-centre'><img className='web-img' src='/Images/AIML/aiml8.svg'></img></div>
      </div>
    </div>

    <div className='web-page-3 container' data-aos="flip-left" data-aos-duration="2000">
      <h1 className='web-head-3'>Continuous Model Monitoring and Optimization</h1>
      <div className='web-detail-3'>
      <div className='image-centre'><img className='web-img' src='/Images/AIML/aiml9.svg'></img></div>
      <p className='web-para-3'>
      Oakton Technologies ensures the longevity and relevance of your AI/ML models through
continuous monitoring and optimization. We proactively address performance issues, adapt
to changing data patterns, and optimize models for sustained accuracy.
      </p>
      </div>
    </div>

    <div className='web-page-4 container' data-aos="flip-right" data-aos-duration="2000">
      <h1 className='web-head-3'>AI Strategy Consulting</h1>
      <div className='web-detail-4'>
      <p className='web-para-3'>
Oakton takes a holistic approach to AI adoption. Our AI strategy
consulting services guide your organization in developing a roadmap for AI integration, ensuring
alignment with business goals, compliance, and industry best practices.
Transform your business with Oakton Technologies' AI/ML services. Collaborate with us, and let's
harness the power of intelligent technology together. Contact us today to embark on a journey of
innovation, insight, and business transformation.
      </p>
      <div className='image-centre'><img className='web-img' src='/Images/AIML/aiml10.svg'></img></div>
      </div>
    </div>

    </div>
  )
} 
