import React from 'react'
import Aboutcomponent from '../Aboutcomponent/Aboutcomponent'

export default function DetailAbout() {
  return (
    <div>
      <Aboutcomponent/>
    </div>
  )
}
