import React from 'react'
import './Innotech.css'

export default function InnoTech() {
  return (
    <div>
    <div className='auto-page-1 container' data-aos="zoom-in-up" data-aos-duration="2000">
      <h1 className='auto-head-1'>INNOVATION</h1>
    </div>
    <div className='auto-page-2 container' data-aos="zoom-in-up" data-aos-duration="2000">
    <div className='auto-img-container-2'>
        <div className='auto-intro-container'>
          <img className='innovate-img-2' src='/Images/Innovate.svg'/>
          <p className='auto-para-2' >
      Innovation is the lifeblood of progress, a transformative force that reshapes industries, economies, and societies. It transcends mere invention, embedding new ideas, technologies, and methodologies into the fabric of our daily lives, making them more efficient, sustainable, and accessible. At its core, innovation is about solving problems in novel ways, whether it's through groundbreaking technologies, revolutionary business models, or transformative social initiatives.
      </p>
        </div>
      </div>
      <p className='auto-para-2' >
      The digital era has accelerated the pace of innovation, with advancements in artificial intelligence, machine learning, and blockchain technology leading the charge. These technologies are not just reshaping traditional sectors like finance, healthcare, and education but are also paving the way for new industries to emerge. The rise of the gig economy, the increasing importance of sustainable solutions, and the push towards digital transformation across sectors are all testaments to innovation's pervasive impact.
      </p>
      <p className='auto-para-2' >
      However, innovation is not without its challenges. It requires a conducive ecosystem that fosters creativity, supports risk-taking, and encourages collaboration across disciplines. Governments, educational institutions, and corporations play crucial roles in nurturing this environment, providing the necessary resources, infrastructure, and policies to fuel innovation.
      </p>
      <p className='auto-para-2' >
      In conclusion, innovation is the cornerstone of future development. It demands a forward-thinking mindset and a relentless pursuit of improvement. As we navigate the complexities of the 21st century, the importance of innovation in driving economic growth, addressing global challenges, and improving the quality of life cannot be overstated. It is up to each one of us to embrace this dynamic force and contribute to a more innovative and prosperous future.
      </p>
    </div>
    </div>
  )
}
