import React from 'react'
import './CloudServ.css'

export default function CloudServ() {
  return (
    <div>

<div className='web-page-1' data-aos="zoom-in-up" data-aos-duration="2000">
  <h1 className='web-head-1 container'>Managed Cloud Services</h1>
  <hr className='container' style={{color:'white'}}/>
</div>

<div className='web-page-2 container' data-aos="flip-up" data-aos-duration="2000">
  <h1 className='web-head-2'>Empowering Your Business with<span style={{color:"#f4d45e"}}> Managed Cloud Services</span></h1>
  <p className='web-para-2'>
  In an era driven by digital transformation, harnessing the power of the cloud is essential for
businesses seeking agility, scalability, and efficiency. Oakton Technologies specializes in delivering
top-notch Managed Cloud Services, ensuring your organization maximizes the benefits of cloud
technology. Explore how we can elevate your business to new heights.
  </p>
</div>

<div className='web-page-3 container' data-aos="slide-left" data-aos-duration="2000">
  <h1 className='web-head-3'>Comprehensive Cloud Management</h1>
  <div className='web-detail-3'>
  <div className='image-centre'><img className='web-img' src='/Images/Cloud/cloud1.svg'></img></div>
  <p className='web-para-3'>
   Oakton Technologies takes the complexity out of cloud adoption. Our Managed Cloud
Services encompass end-to-end management, from planning and migration to ongoing
optimization. Focus on your business objectives while we handle the intricacies of your cloud
infrastructure.
  </p>
  </div>
</div>

<div className='web-page-4 container' data-aos="slide-right" data-aos-duration="2000">
  <h1 className='web-head-3'>Cloud Migration Expertise</h1>
  <div className='web-detail-4'>
  <p className='web-para-3'>
  Seamlessly transition your applications and data to the cloud with Oakton's expertise in
cloud migration. Our team ensures a smooth and secure migration process, minimizing
downtime and maximizing the benefits of your cloud investment.
  </p>
  <div className='image-centre'><img className='web-img' src='/Images/Cloud/cloud2.svg'></img></div>
  </div>
</div>

<div className='web-page-3 container' data-aos="zoom-in" data-aos-duration="2000">
  <h1 className='web-head-3'>Multi-Cloud Solutions</h1>
  <div className='web-detail-3'>
  <div className='image-centre'><img className='web-img' src='/Images/Cloud/cloud3.svg'></img></div>
  <p className='web-para-3'>
  Embrace flexibility with Oakton's multi-cloud solutions. Whether you choose public, private,
or hybrid cloud environments, we tailor our services to meet your unique requirements.
Enjoy the advantages of multiple cloud providers without the complexities.
  </p>
  </div>
</div>

<div className='web-page-4 container' data-aos="zoom-out" data-aos-duration="2000">
  <h1 className='web-head-3'>Security and Compliance</h1>
  <div className='web-detail-4'>
  <p className='web-para-3'>
  Trust is paramount in the cloud. Oakton prioritizes the security of your data and applications.
Our Managed Cloud Services include robust security measures, ensuring compliance with
industry regulations and safeguarding your digital assets.
  </p>
  <div className='image-centre'><img className='web-img' src='/Images/Cloud/cloud4.svg'></img></div>
  </div>
</div>

<div className='web-page-3 container' data-aos="flip-left" data-aos-duration="2000">
  <h1 className='web-head-3'>Continuous Monitoring and Optimization</h1>
  <div className='web-detail-3'>
  <div className='image-centre'><img className='web-img' src='/Images/Cloud/cloud5.svg'></img></div>
  <p className='web-para-3'>
  Enhance the performance and cost-effectiveness of your cloud infrastructure with Oakton's
continuous monitoring and optimization. We proactively identify opportunities for
improvement, ensuring your cloud resources are utilized efficiently.
  </p>
  </div>
</div>

<div className='web-page-4 container' data-aos="flip-right" data-aos-duration="2000">
  <h1 className='web-head-3'>Disaster Recovery and Business Continuity</h1>
  <div className='web-detail-4'>
  <p className='web-para-3'>
  Protect your business against unforeseen events with Oakton's disaster recovery and
business continuity solutions. We design robust strategies to ensure data integrity,
application availability, and seamless operations even in the face of disruptions.
  </p>
  <div className='image-centre'><img className='web-img' src='/Images/Cloud/cloud6.svg'></img></div>
  </div>
</div>

<div className='web-page-3 container' data-aos="flip-left" data-aos-duration="2000">
  <h1 className='web-head-3'>Scalability on Demand</h1>
  <div className='web-detail-3'>
  <div className='image-centre'><img className='web-img' src='/Images/Cloud/cloud7.svg'></img></div>
  <p className='web-para-3'>
  Leverage the scalability of the cloud with Oakton's Managed Cloud Services. Whether you're
experiencing growth or seasonal fluctuations, we ensure your infrastructure scales
seamlessly to meet the demands of your business.
  </p>
  </div>
</div>

<div className='web-page-4 container' data-aos="flip-right" data-aos-duration="2000">
  <h1 className='web-head-3'>24/7 Support and Monitoring</h1>
  <div className='web-detail-4'>
  <p className='web-para-3'>
  Oakton Technologies provides round-the-clock support and monitoring for your cloud
environment. Our dedicated team is ready to address issues promptly, ensuring the
availability and reliability of your critical systems.
  </p>
  <div className='image-centre'><img className='web-img' src='/Images/Cloud/cloud8.svg'></img></div>
  </div>
</div>

<div className='web-page-3 container' data-aos="flip-left" data-aos-duration="2000">
  <h1 className='web-head-3'>Cost Management and Optimization</h1>
  <div className='web-detail-3'>
  <div className='image-centre'><img className='web-img' src='/Images/Cloud/cloud9.svg'></img></div>
  <p className='web-para-3'>
  Keep your cloud costs in check with Oakton's cost management and optimization services.
We analyze usage patterns, implement cost-saving measures, and provide transparent
reporting to optimize your cloud expenditure.
  </p>
  </div>
</div>

<div className='web-page-4 container' data-aos="flip-right" data-aos-duration="2000">
  <h1 className='web-head-3'>Personalized Consultation and Strategy</h1>
  <div className='web-detail-4'>
  <p className='web-para-3'>
  Oakton takes a personalized approach to Managed
Cloud Services. Our experts work closely with you to understand your business goals, tailoring a
cloud strategy that aligns with your objectives and drives long-term success.
Transform your business with Oakton Technologies' Managed Cloud Services. Collaborate with us,
and let's navigate the cloud landscape together. Contact us today to elevate your business through
scalable, secure, and efficient cloud solutions.
  </p>
  <div className='image-centre'><img className='web-img' src='/Images/Cloud/cloud10.svg'></img></div>
  </div>
</div>

    </div>
  )
}
