import React from 'react'
import './UIUXdev.css'

export default function UIUXdev() {
  return (
    <div>

      <div className='web-page-1' data-aos="zoom-in-up" data-aos-duration="2000">
        <h1 className='web-head-1 container'>UI / UX Design Services</h1>
        <hr className='container' style={{color:'white'}}/>
      </div>

      <div className='web-page-2 container' data-aos="flip-up" data-aos-duration="2000">
        <h1 className='web-head-2'>Elevate User Experiences with Our<span style={{color:"#f4d45e"}}> UI/UX Services</span></h1>
        <p className='web-para-2'>
        At Oakton Technologies, we understand that exceptional user experiences are the cornerstone of
successful digital products. Our UI/UX services are crafted to not only meet but exceed user
expectations, ensuring that every interaction leaves a lasting and positive impression. Explore how
our expertise in UI/UX can transform your digital presence.
        </p>
      </div>

      <div className='web-page-3 container' data-aos="slide-left" data-aos-duration="2000">
        <h1 className='web-head-3'>Intuitive User Interface Design</h1>
        <div className='web-detail-3'>
        <div className='image-centre'><img className='web-img' src='/Images/UIUX/uiux1.svg'></img></div>
        <p className='web-para-3'>
        At Oakton, we believe in the power of simplicity. Our UI designers create intuitive interfaces
that guide users seamlessly through your digital platforms. From clean layouts to userfriendly navigation, we prioritize a design that enhances usability.
        </p>
        </div>
      </div>

      <div className='web-page-4 container' data-aos="slide-right" data-aos-duration="2000">
        <h1 className='web-head-3'>User-Centric Design Approach</h1>
        <div className='web-detail-4'>
        <p className='web-para-3'>
         Our UI/UX services revolve around your users. We take the time to understand their needs,
behaviors, and preferences, ensuring that every design decision is rooted in a user-centric
approach. The result? Digital solutions that resonate with your audience.
        </p>
        <div className='image-centre'><img className='web-img' src='/Images/UIUX/uiux2.svg'></img></div>
        </div>
      </div>

      <div className='web-page-3 container' data-aos="zoom-in" data-aos-duration="2000">
        <h1 className='web-head-3'>Responsive Design for All Devices</h1>
        <div className='web-detail-3'>
        <div className='image-centre'><img className='web-img' src='/Images/UIUX/uiux3.svg'></img></div>
        <p className='web-para-3'>
        In a world of diverse devices, Oakton ensures your digital presence remains consistent and
engaging across platforms. Our responsive design approach guarantees that your users have
an optimal experience whether on desktops, tablets, or smartphones.
        </p>
        </div>
      </div>

      <div className='web-page-4 container' data-aos="zoom-out" data-aos-duration="2000">
        <h1 className='web-head-3'>Prototyping and Iterative Design</h1>
        <div className='web-detail-4'>
        <p className='web-para-3'>
        We believe in refining and perfecting our designs through prototyping and iterative
processes. This ensures that your UI/UX meets evolving requirements, incorporates
feedback, and stays aligned with the changing landscape of user expectations.
        </p>
        <div className='image-centre'><img className='web-img' src='/Images/UIUX/uiux4.svg'></img></div>
        </div>
      </div>

      <div className='web-page-3 container' data-aos="flip-left" data-aos-duration="2000">
        <h1 className='web-head-3'>Accessibility and Inclusivity</h1>
        <div className='web-detail-3'>
        <div className='image-centre'><img className='web-img' src='/Images/UIUX/uiux5.svg'></img></div>
        <p className='web-para-3'>
        Oakton is committed to creating digital experiences that are accessible to all. Our UI/UX
designs adhere to accessibility standards, ensuring that every user, regardless of ability, can
navigate and interact seamlessly with your digital platforms.
        </p>
        </div>
      </div>

      <div className='web-page-4 container' data-aos="flip-right" data-aos-duration="2000">
        <h1 className='web-head-3'>Visual Storytelling and Brand Consistency</h1>
        <div className='web-detail-4'>
        <p className='web-para-3'>
        Your brand's story is told through visuals. Oakton's UI/UX services not only prioritize a
visually compelling design but also ensure that it aligns seamlessly with your brand identity.
Consistency across digital touchpoints enhances brand recognition and trust.
        </p>
        <div className='image-centre'><img className='web-img' src='/Images/UIUX/uiux6.svg'></img></div>
        </div>
      </div>

      <div className='web-page-3 container' data-aos="flip-left" data-aos-duration="2000">
        <h1 className='web-head-3'>Usability Testing and Optimization</h1>
        <div className='web-detail-3'>
        <div className='image-centre'><img className='web-img' src='/Images/UIUX/uiux7.svg'></img></div>
        <p className='web-para-3'>
        Our commitment to excellence extends to usability testing. We rigorously evaluate our
designs through testing scenarios to identify areas for improvement. Continuous
optimization ensures that your UI/UX remains top-notch throughout the lifecycle of your
digital products.
        </p>
        </div>
      </div>

      <div className='web-page-4 container' data-aos="flip-right" data-aos-duration="2000">
        <h1 className='web-head-3'>Cutting-Edge Design Trends</h1>
        <div className='web-detail-4'>
        <p className='web-para-3'>
        Stay ahead in the digital landscape with Oakton's incorporation of cutting-edge design
trends. From modern aesthetics to innovative interactions, our UI/UX designs are informed
by the latest trends to keep your digital presence fresh and engaging.
Transform your digital interactions with Oakton Technologies. Collaborate with us, and let's create
UI/UX designs that captivate, engage, and leave a lasting impact on your users. Contact us today to
embark on a journey of elevated user experiences.
        </p>
        <div className='image-centre'><img className='web-img' src='/Images/UIUX/uiux8.svg'></img></div>
        </div>
      </div>

    </div>
  )
}
