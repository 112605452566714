import React from 'react'
import Formpage from '../FormPage/Formpage'
import { Link } from "react-router-dom";
import './Navbar.css'

export default function Navbar() {

  const homeHandler = ()=>{
      document.title = "Oakton Technologies - Home"
  }

  const aboutHandler = ()=>{
    document.title = "Oakton Technologies - About"
  }

  const serviceHandler = ()=>{
    document.title = "Oakton Technologies - Services"
  }

  const carrerHandler = ()=>{
    document.title = "Oakton Technologies - Carrers"
  }

  const contactHandler = ()=>{
    document.title = "Oakton Technologies - Contacts"
  }


  return (
    <div>
      <nav class="navbar navbar-expand-lg border-body">¯

        <div class="container-fluid">    
            <Link to="/" onClick={homeHandler}><img src="/Images/Logo.png" alt="Logo" class="d-inline-block align-text-top logo"/></Link>

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation" style={{color:"white"}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-menu"><line x1="4" x2="20" y1="12" y2="12"/><line x1="4" x2="20" y1="6" y2="6"/><line x1="4" x2="20" y1="18" y2="18"/></svg>
            </button>

            <div class="collapse navbar-collapse links" id="navbarText">
                <ul class="navbar-nav links-gap">
                    <li class="nav-item">
                    <Link class="nav-link" aria-current="page" onClick={homeHandler} to="/">Home</Link>
                    </li>
                    <li class="nav-item">
                    <Link class="nav-link" to="/aboutus" onClick={aboutHandler}>About Us</Link>
                    </li>
                    <li class="nav-item">
                    <Link class="nav-link" to="/services" onClick={serviceHandler}>Services</Link>
                    </li>
                    <li class="nav-item">
                    <Link class="nav-link" to="/carrers" onClick={carrerHandler}>Careers</Link>
                    </li>
                    <li class="nav-item">
                    <Link class="nav-link" to="/contact" onClick={contactHandler}>Contact</Link>
                    </li>
                    <li class="nav-item">
                     <button type="button" class="btn nav-butn" data-bs-toggle="modal" data-bs-target="#exampleModal">Talk to Us</button>
                    </li>
                </ul>
            </div>
        </div>

      </nav>

      <Formpage/>
    </div>
  )
}
