import React from 'react'
import './Softwaredev.css'

export default function Softwaredev() {
  return (
    <div>

    <div className='web-page-1' data-aos="zoom-in-up" data-aos-duration="2000">
      <h1 className='web-head-1 container'>SOFTWARE DEVELOPMENT</h1>
      <hr className='container' style={{color:'white'}}/>
    </div>

    <div className='web-page-2 container' data-aos="flip-up" data-aos-duration="2000">
      <h1 className='web-head-2'>Elevate User Experiences with Our<span style={{color:"#f4d45e"}}> SOFTWARE DEVELOPMENT </span></h1>
      <p className='web-para-2'>
      Custom software development involves creating tailored software solutions that address specific
needs and requirements of a particular business or organization. Here are some examples of custom
software development.
      </p>
    </div>

    <div className='web-page-3 container' data-aos="slide-left" data-aos-duration="2000">
      <h1 className='web-head-3'>Customer Relationship Management (CRM) Systems</h1>
      <div className='web-detail-3'>
      <div className='image-centre'><img className='web-img' src='/Images/Software/software1.svg'></img></div>
      <p className='web-para-3'>
      A custom CRM system is designed to manage and streamline interactions with
customers. It may include features such as contact management, sales tracking, and
customer support.
      </p>
      </div>
    </div>

    <div className='web-page-4 container' data-aos="slide-right" data-aos-duration="2000">
      <h1 className='web-head-3'>Enterprise Resource Planning (ERP) Software</h1>
      <div className='web-detail-4'>
      <p className='web-para-3'>
      ERP systems integrate various business processes, such as finance, human resources,
and inventory management, into a unified platform. Custom ERP solutions are
crafted to fit the unique workflows of a specific organization.
      </p>
      <div className='image-centre'><img className='web-img' src='/Images/Software/software2.svg'></img></div>
      </div>
    </div>

    <div className='web-page-3 container' data-aos="zoom-in" data-aos-duration="2000">
      <h1 className='web-head-3'>Content Management Systems (CMS)</h1>
      <div className='web-detail-3'>
      <div className='image-centre'><img className='web-img' src='/Images/Software/software3.svg'></img></div>
      <p className='web-para-3'>
      A custom CMS allows businesses to manage and organize digital content, such as
text, images, and multimedia, on their websites. It can be tailored to specific content
creation and publishing needs.
      </p>
      </div>
    </div>

    <div className='web-page-4 container' data-aos="zoom-out" data-aos-duration="2000">
      <h1 className='web-head-3'>Inventory Management Systems</h1>
      <div className='web-detail-4'>
      <p className='web-para-3'>
      Organizations with complex inventory requirements often opt for custom inventory
management systems. These systems help optimize stock levels, streamline order
fulfillment, and track inventory movements.
      </p>
      <div className='image-centre'><img className='web-img' src='/Images/Software/software4.svg'></img></div>
      </div>
    </div>

    <div className='web-page-3 container' data-aos="flip-left" data-aos-duration="2000">
      <h1 className='web-head-3'>Project Management Software</h1>
      <div className='web-detail-3'>
      <div className='image-centre'><img className='web-img' src='/Images/Software/software5.svg'></img></div>
      <p className='web-para-3'>
      Custom project management software is designed to meet the unique project
workflows of a business. It may include features like task tracking, team
collaboration, and resource allocation.
      </p>
      </div>
    </div>

    <div className='web-page-4 container' data-aos="flip-right" data-aos-duration="2000">
      <h1 className='web-head-3'>E-commerce Platforms</h1>
      <div className='web-detail-4'>
      <p className='web-para-3'>
      Businesses with specific requirements for online selling often invest in custom ecommerce platforms. These platforms can be tailored to support unique product
types, pricing structures, and payment gateways.
      </p>
      <div className='image-centre'><img className='web-img' src='/Images/Software/software6.svg'></img></div>
      </div>
    </div>

    <div className='web-page-3 container' data-aos="flip-left" data-aos-duration="2000">
      <h1 className='web-head-3'>Healthcare Information Systems</h1>
      <div className='web-detail-3'>
      <div className='image-centre'><img className='web-img' src='/Images/Software/software7.svg'></img></div>
      <p className='web-para-3'>
      Custom software is crucial in healthcare for managing patient records, scheduling
appointments, and ensuring compliance with industry regulations such as HIPAA.
      </p>
      </div>
    </div>

    <div className='web-page-4 container' data-aos="flip-right" data-aos-duration="2000">
      <h1 className='web-head-3'>Learning Management Systems (LMS)</h1>
      <div className='web-detail-4'>
      <p className='web-para-3'>
      Educational institutions or companies with specific training needs often opt for
custom LMS solutions. These platforms can be designed to deliver and track online
courses, assessments, and employee training.
      </p>
      <div className='image-centre'><img className='web-img' src='/Images/Software/software8.svg'></img></div>
      </div>
    </div>

    <div className='web-page-3 container' data-aos="flip-left" data-aos-duration="2000">
      <h1 className='web-head-3'>Analytics and Reporting Tools</h1>
      <div className='web-detail-3'>
      <div className='image-centre'><img className='web-img' src='/Images/Software/software9.svg'></img></div>
      <p className='web-para-3'>
      Businesses may require custom analytics and reporting tools to gather insights from
their data. These tools can be tailored to present information in a format that suits
the specific needs of the organization.
      </p>
      </div>
    </div>

    <div className='web-page-4 container' data-aos="flip-right" data-aos-duration="2000">
      <h1 className='web-head-3'>Booking and Reservation Systems</h1>
      <div className='web-detail-4'>
      <p className='web-para-3'>
      Custom booking and reservation systems are common in industries like hospitality,
travel, and event management. They allow businesses to manage bookings,
appointments, and reservations efficiently.
These examples highlight the versatility of custom software development in addressing diverse
business challenges and optimizing processes for specific industries and organizational requirements
      </p>
      <div className='image-centre'><img className='web-img' src='/Images/Software/software10.svg'></img></div>
      </div>
    </div>

  </div>
  )
}
