import React from 'react'
import './Servicepage.css'
import { Paper } from '@mui/material'
import { Link } from 'react-router-dom'

export default function Servicepage() {
  return (
    <div data-aos="zoom-in-up" data-aos-duration="2000" className='servicepage'>
      <div className='title1'>
        <h5 className='subhead1'>WHAT WE DO</h5>
        <h1 className='heads1'>Explore Our</h1>
        <h1 className='heads2'>Suite of Services</h1>
        <h1 className='heads1'>Offered</h1>
      </div>
      <div className='servicebox'>
        <div className='servicebox1 container'>

            <Paper elevation={5} style={{backgroundColor:"rgb(224, 122, 95)"}} data-aos="flip-up" data-aos-duration="2000" data-aos-delay="1000"  className='box1'>
                    <h2 className='hovercolor' style={{textAlign:"left"}}>01</h2>
                    <hr className='hovercolor line'/>
                    <p>
                        <h3 className='service-box-head' style={{textAlign:"left"}}>WEB DEVELOPMENT</h3> 
                        <p style={{textAlign:"left"}}>At Oakton Technologies, we are dedicated to revolutionizing the digital landscape through cuttingedge web app development. Our team of seasoned developers crafts dynamic and innovative web applications tailored to meet the unique needs of your business.</p>
                    </p>
                    <Link to="/web" style={{textDecoration:"none"}}><button type="button" class="btn btn-outline service-buton" style={{marginTop:"5px" , marginLeft:"20px"  , display:"flex" , textAlign:"center" , marginLeft:"0%"}}>Know More...</button></Link>
            </Paper>

 
            <Paper elevation={5} style={{backgroundColor:"rgb(129, 178, 154)"}} data-aos="flip-up" data-aos-duration="2000" data-aos-delay="1000"  className='box1 boxdown'>
                <h2 className='hovercolor' style={{textAlign:"left"}}>02</h2>
                <hr className='hovercolor line'/>
                <p>
                    <h3 className='service-box-head'  style={{textAlign:"left"}}>UX/UI DESIGN SERVICES</h3> 
                    <p style={{textAlign:"left"}}>At Oakton Technologies, we understand that exceptional user experiences are the cornerstone of successful digital products. Our UI/UX services are crafted to not only meet but exceed user expectations, ensuring that every interaction leaves a lasting and positive impression.</p>
                </p>
                <Link to="/uiux" style={{textDecoration:"none"}}><button type="button" class="btn btn-outline service-buton" style={{marginTop:"5px" , marginLeft:"20px"  , display:"flex" , textAlign:"center" , marginLeft:"0%"}}>Know More...</button></Link>
            </Paper>


            <Paper elevation={5} style={{backgroundColor:"rgb(152, 193, 217)"}} data-aos="flip-up" data-aos-duration="2000" data-aos-delay="1000"  className='box1 boxdown'>
                <h2 className='hovercolor' style={{textAlign:"left"}}>03</h2>
                <hr className='hovercolor line'/>
                <p>
                    <h3 className='service-box-head'  style={{textAlign:"left"}}>MOBILE APP DEVELOPMENT</h3> 
                    <p style={{textAlign:"left"}}>At Oakton Technologies, we specialize in transforming ideas into powerful, user-centric mobile applications. With a keen eye for innovation and a commitment to excellence, our mobile app development services are designed to elevate your digital presence.</p>
                </p>
                <Link to="/mobile" style={{textDecoration:"none"}}><button type="button" class="btn btn-outline service-buton" style={{marginTop:"5px" , marginLeft:"20px"  , display:"flex" , textAlign:"center" , marginLeft:"0%"}}>Know More...</button></Link>
            </Paper>


        </div>
        <div className='servicebox2 container'>

            <Paper elevation={5} style={{backgroundColor:"rgb(242, 204, 143)"}} data-aos="flip-up" data-aos-duration="2000" data-aos-delay="1000"  className='box2'>
                    <h2 className='hovercolor' style={{textAlign:"left"}}>04</h2>
                    <hr className='hovercolor line'/>
                    <p>
                        <h3 className='service-box-head'  style={{textAlign:"left"}}>SOFTWARE DEVELOPMENT</h3> 
                        <p style={{textAlign:"left"}}>Custom software development involves creating tailored software solutions that address specific needs and requirements of a particular business or organization</p>
                    </p>
                    <Link to="/software" style={{textDecoration:"none"}}><button type="button" class="btn btn-outline service-buton" style={{marginTop:"5px" , marginLeft:"20px"  , display:"flex" , textAlign:"center" , marginLeft:"0%"}}>Know More...</button></Link>
            </Paper>


            <Paper elevation={5} style={{backgroundColor:"rgb(244, 241, 222)"}} data-aos="flip-up" data-aos-duration="2000" data-aos-delay="1000"  className='box2 boxdown'>
                    <h2 className='hovercolor' style={{textAlign:"left"}}>05</h2>
                    <hr className='hovercolor line'/>
                    <p>
                        <h3 className='service-box-head'  style={{textAlign:"left"}}>MANAGED CLOUD SERVICES</h3> 
                        <p style={{textAlign:"left"}}>In an era driven by digital transformation, harnessing the power of the cloud is essential for businesses seeking agility, scalability, and efficiency. Oakton Technologies specializes in delivering top-notch Managed Cloud Services, ensuring your organization maximizes the benefits of cloud technology</p>
                    </p>
                    <Link to="/cloud" style={{textDecoration:"none"}}><button type="button" class="btn btn-outline service-buton" style={{marginTop:"5px" , marginLeft:"20px"  , display:"flex" , textAlign:"center" , marginLeft:"0%"}}>Know More...</button></Link>
            </Paper>


            <Paper elevation={5} style={{backgroundColor:"rgb(255 242 179)"}} data-aos="flip-up" data-aos-duration="2000" data-aos-delay="1000"  className='box2 boxdown'>
                    <h2 className='hovercolor' style={{textAlign:"left"}}>06</h2>
                    <hr className='hovercolor line'/>
                    <p>
                        <h3 className='service-box-head'  style={{textAlign:"left"}}>AI/ML SERVICES</h3> 
                        <p style={{textAlign:"left"}}>In the era of intelligent technology, harnessing the power of Artificial Intelligence (AI) and Machine Learning (ML) is key to staying ahead in the digital landscape. At Oakton Technologies, we specialize in delivering cutting-edge AI/ML services, empowering businesses to unlock new possibilities and drive innovation.</p>
                    </p>
                    <Link to="/aiml" style={{textDecoration:"none"}}><button type="button" class="btn btn-outline service-buton" style={{marginTop:"5px" , marginLeft:"20px"  , display:"flex" , textAlign:"center" , marginLeft:"0%"}}>Know More...</button></Link>
            </Paper>

        </div>
      </div>
    </div>
  )
}
