import React from 'react'
import './AutoTech.css'

export default function AutoTech() {
  return (
    <div>
      <div className='auto-page-1 container' data-aos="zoom-in-up" data-aos-duration="2000">
        <h1 className='auto-head-1'>AUTOMATION</h1>
      </div>
      <div className='auto-page-2 container' data-aos="zoom-in-up" data-aos-duration="2000">
      <div className='auto-img-container-2'>
        <div className='auto-intro-container'>
        <img className='auto-img-2' src='/Images/Automate.svg'/>
        <p className='auto-para-2' >
        Automation stands as a pivotal element in the contemporary narrative of technological evolution, fundamentally altering the landscape of work, productivity, and efficiency across diverse sectors. At its essence, automation involves the application of technology to perform tasks with minimal human intervention, spanning from simple mechanical processes to complex cognitive functions traditionally requiring human intellect.
        </p>
        </div>
        </div>
        <p className='auto-para-2' >
        The surge in automation, propelled by breakthroughs in robotics, artificial intelligence (AI), and machine learning, has ushered in a new era of efficiency and precision. Industries such as manufacturing, logistics, and customer service are witnessing a paradigm shift, with automated systems enhancing output, reducing errors, and streamlining operations. This transition not only boosts productivity but also paves the way for innovation, allowing human creativity to focus on strategic and complex problem-solving tasks.
        </p>
        <p className='auto-para-2' >
        Moreover, automation holds the promise of reshaping the job market. While it may displace certain manual and repetitive roles, it simultaneously generates new opportunities in fields like AI development, data analysis, and technology maintenance. This shift necessitates a reimagining of workforce skills, emphasizing adaptability, technical proficiency, and lifelong learning.
        Despite its myriad benefits, the march of automation brings forth ethical and societal implications that warrant careful consideration. Issues such as workforce displacement, income inequality, and the digital divide call for thoughtful policies and inclusive strategies to ensure that the benefits of automation are equitably distributed.
        </p>
        <p className='auto-para-2' >
        In conclusion, automation is not just a technological phenomenon but a catalyst for economic, social, and cultural transformation. Embracing this change requires a balanced approach, one that harnesses the potential of automation while addressing its challenges, to forge a future where technology and humanity advance in harmony.
        </p>
      </div>
    </div>
  )
}
