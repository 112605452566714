import { Paper } from '@mui/material'
import React from 'react'
import './Contactcomponent.css'


export default function Contactcomponent() {
  return (
    <div>
      <form action="https://formsubmit.co/oaktontechuk@gmail.com" method="POST">
        <Paper className='form-page container' data-aos="zoom-in-up" data-aos-duration="2000">
            <div>
                <h1 className='head-contact-1'>REACH OUT</h1>
                <hr className='container'/>
            </div>
            <div> 
                <p className='head-contact'>Full Name :</p>
                <input type="name" class="form-control" id="floatingInput"/>

                <p className='head-contact'>Email :</p>
                <input type="email" class="form-control" id="floatingPassword"/>


                <p className='head-contact'>Organisation Name :</p>
                <input type="" class="form-control" id="floatingPassword"/>

                <p className='head-contact'>Requirement Brief in 300 to 400 Words :</p>
                <input type="text" class="form-control" id="floatingPassword"/>

            </div>
            <div>
                    <button type="submit" class="btn contact-btn">Save changes</button>
            </div>
        </Paper>
      </form>
    </div>
  )
}
