import React, { useState, useEffect } from 'react';
import './Carrercomponent.css';
import { Paper } from '@mui/material';

export default function Carrercomponent() {

  return (
    <>
      <div className='carrers-1 container' data-aos="zoom-in-up" data-aos-duration="2000" >
        <h1 className='carrer-head-1'>Careers</h1>
        <hr className='container' style={{color:"white"}}/>
      </div>

      <p className='carrer-para-1 container' data-aos="zoom-in-up" data-aos-duration="2000">
        At Oakton Technologies, we are always on the lookout for passionate individuals who share our
        commitment to innovation and excellence. If you are ready to embark on a rewarding career journey
        and contribute to cutting-edge projects, we invite you to explore our current career opportunities.
      </p>

      <Paper className='carrer-3 container' data-aos="zoom-in-up" data-aos-duration="2000">
      <h1 className='carrer-head-3'>Why Oakton Technologies?</h1>
      <hr className='container'/>
      <p className='carrer-para-3'>
        <h3 className='carrer-para-head-3'>Innovation</h3>
        <p className='carrer-para-para-3'>Be part of a team that thrives on innovation, pushing the boundaries of technology to create solutions that make a real impact.</p>

        <h3 className='carrer-para-head-3'>Collaboration</h3>
        <p className='carrer-para-para-3'>Join a culture of collaboration where your ideas are valued, and teamwork is at the heart of everything we do.</p>

        <h3 className='carrer-para-head-3'>Growth</h3>
        <p className='carrer-para-para-3'>Oakton Technologies is a place where you can grow both professionally and personally. We provide opportunities for skill development and advancement within the company.</p>

        <h3 className='carrer-para-head-3'>Diversity</h3>
        <p className='carrer-para-para-3'>Embrace a diverse and inclusive work environment that fosters creativity, learning, and mutual respect.</p>
      </p>
      </Paper>

      <Paper className='carrer-3 container' style={{marginTop:"5%"}} data-aos="zoom-in-up" data-aos-duration="2000">
      <h1 className='carrer-head-3'>How to Apply?</h1>
      <hr className='container'/>
      <p className='carrer-para-para-3'>
      To explore current career opportunities at Oakton Technologies, please review our open positions
[link to your career page]. If you find a position that aligns with your skills and career aspirations, we
encourage you to apply by sending your resume and a cover letter to <span style={{color:"red"}}>info@oaktontechnologies.com </span>.
      </p>
      </Paper>
    </>
  );
}


