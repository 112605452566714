import React from 'react'
import  './Aboutpage.css'
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';

export default function Aboutpage() {
  return (
    <div  data-aos="zoom-in" data-aos-duration="2000" className='Aboutpage container'>
      <img className='sideImg' src='Images/LandingAbout.svg'/>
      <div className='Writtenpage'>
        <h1 className='heading1'>About Oakton Technologies</h1>
        <p className='paras1'>Headquartered in Aberdeen, United Kingdom.Oakton Technologies is a software development company that designs and delivers tailor-made digital solutions to enterprises and startups worldwide. Our team of full-stack developers, designers, and innovators has created digital solutions for almost all sectors.</p>
        <Link to="/aboutus" className='about-buton' style={{textDecoration:"none"}}><button type="button" class="btn buton btn-outline">Know More...</button></Link>
      </div>
    </div>
  )
}
