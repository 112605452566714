import React from 'react'
import './Tech.css'

export default function Tech() {
  return (
    <div>
    <div className='auto-page-1 container' data-aos="zoom-in-up" data-aos-duration="2000">
      <h1 className='auto-head-1'>TECHNOLOGY</h1>
    </div>
    <div className='auto-page-2 container' data-aos="zoom-in-up" data-aos-duration="2000">
      <div className='auto-img-container-2'>
        <div className='auto-intro-container'>
        <img className='tech-img-2' src='/Images/Tech.svg'/>
        <p className='auto-para-2' >
        The realm of technology is an ever-expanding frontier, characterized by relentless innovation and transformative potential. It serves as the backbone of modern society, revolutionizing the way we communicate, work, and live. Technology's rapid evolution continues to blur the lines between the physical and digital worlds, creating a tapestry of interconnected experiences that redefine the boundaries of possibility.
        </p>
        </div>
      </div>
      <p className='auto-para-2' >
      At the heart of this technological renaissance are advancements in computing power, artificial intelligence (AI), and the Internet of Things (IoT). These pillars of modern tech are not just enhancing efficiency and productivity across industries but are also fostering new ways of human interaction and creativity. From smart homes that anticipate our needs to AI-driven healthcare systems that offer personalized treatments, technology is becoming increasingly integrated into every aspect of our lives.
      </p>
      <p className='auto-para-2' >
      The digital revolution, characterized by the ubiquity of smartphones and the internet, has democratized information access, empowering individuals with knowledge and connectivity. Social media platforms and e-commerce have reshaped social dynamics and consumer behavior, highlighting technology's role in driving cultural and economic shifts.
However, the rapid pace of technological change also presents challenges, including privacy concerns, cybersecurity threats, and the digital divide. These issues underscore the need for responsible innovation and equitable access to technology, ensuring that its benefits are shared broadly and ethically.
      </p>
      <p className='auto-para-2' >
      In conclusion, technology is not merely a set of tools and devices but a driving force of human progress. As we stand on the brink of new technological horizons, from quantum computing to biotechnology, our collective responsibility is to harness this potential responsibly, ensuring a future where technology serves to uplift, empower, and unite.
      </p>
    </div>
    </div>
  )
}
