import React from 'react'
import './Webdev.css'

export default function Webdev() {
  return (
    <div>
      <div className='web-page-1' data-aos="zoom-in-up" data-aos-duration="2000">
        <h1 className='web-head-1 container'>Web Development</h1>
        <hr className='container' style={{color:'white'}}/>
      </div>

      <div className='web-page-2 container' data-aos="flip-up" data-aos-duration="2000">
        <h1 className='web-head-2'> Redefining <span style={{color:"#f4d45e"}}> Web App Experiences </span></h1>
        <p className='web-para-2'>
        At Oakton Technologies, we are dedicated to revolutionizing the digital landscape through cuttingedge web app development. Our team of seasoned developers crafts dynamic and innovative web
applications tailored to meet the unique needs of your business. Explore how Oakton can transform
your digital presence with our web app development services.
        </p>
      </div>

      <div className='web-page-3 container' data-aos="slide-left" data-aos-duration="2000">
        <h1 className='web-head-3'>Custom Web App Development</h1>
        <div className='web-detail-3'>
        <div className='image-centre'><img className='web-img' src='/Images/Website/Web3.svg'></img></div>
        <p className='web-para-3'>
        Oakton Technologies specializes in creating bespoke web applications that cater specifically
to your business requirements. From concept to deployment, our team ensures that your
web app is a true reflection of your vision and goals.
        </p>
        </div>
      </div>

      <div className='web-page-4 container' data-aos="slide-right" data-aos-duration="2000">
        <h1 className='web-head-3'>Responsive and Scalable Designs</h1>
        <div className='web-detail-4'>
        <p className='web-para-3'>
        Our web app designs prioritize responsiveness, ensuring a seamless user experience across
devices. Whether accessed on desktops, tablets, or smartphones, your web app will adapt
effortlessly to different screen sizes.
        </p>
        <div className='image-centre'><img className='web-img' src='/Images/Website/Web4.svg'></img></div>
        </div>
      </div>

      <div className='web-page-3 container' data-aos="zoom-in" data-aos-duration="2000">
        <h1 className='web-head-3'>Cross-Browser Compatibility</h1>
        <div className='web-detail-3'>
        <div className='image-centre'><img className='web-img' src='/Images/Website/Web5.svg'></img></div>
        <p className='web-para-3'>
        Oakton's web app development ensures cross-browser compatibility, guaranteeing a
consistent and reliable experience for users across various web browsers. Your application
will perform flawlessly, regardless of the browser your audience chooses.
        </p>
        </div>
      </div>

      <div className='web-page-4 container' data-aos="zoom-out" data-aos-duration="2000">
        <h1 className='web-head-3'>Frontend and Backend Development</h1>
        <div className='web-detail-4'>
        <p className='web-para-3'>
        Benefit from Oakton's comprehensive approach to web app development. Our expertise
spans both frontend and backend development, ensuring a cohesive and robust architecture
that powers your application's user interface and functionality.
        </p>
        <div className='image-centre'><img className='web-img' src='/Images/Website/Web6.svg'></img></div>
        </div>
      </div>

      <div className='web-page-3 container' data-aos="flip-left" data-aos-duration="2000">
        <h1 className='web-head-3'>Progressive Web Apps (PWAs)</h1>
        <div className='web-detail-3'>
        <div className='image-centre'><img className='web-img' src='/Images/Website/Web7.svg'></img></div>
        <p className='web-para-3'>
        Oakton leverages the power of Progressive Web Apps to deliver an app-like experience
directly through web browsers. Enjoy features such as offline functionality, push
notifications, and fast loading times, enhancing user engagement.
        </p>
        </div>
      </div>

      <div className='web-page-4 container' data-aos="flip-right" data-aos-duration="2000">
        <h1 className='web-head-3'>E-commerce Solutions</h1>
        <div className='web-detail-4'>
        <p className='web-para-3'>
        Elevate your online business with Oakton's e-commerce web app solutions. Our developers
craft secure and user-friendly platforms, enabling seamless transactions, inventory
management, and an overall enhanced shopping experience for your customers.
        </p>
        <div className='image-centre'><img className='web-img' src='/Images/Website/Web8.svg'></img></div>
        </div>
      </div>

      <div className='web-page-3 container' data-aos="fade-right" data-aos-duration="2000">
        <h1 className='web-head-3'>Content Management Systems (CMS)</h1>
        <div className='web-detail-3'>
        <div className='image-centre'><img className='web-img' src='/Images/Website/Web9.svg'></img></div>
        <p className='web-para-3'>
        Take control of your digital content with Oakton's custom CMS solutions. We empower you
to manage, update, and organize your website content efficiently, providing you with the
flexibility and control you need.
        </p>
        </div>
      </div>

      <div className='web-page-4 container' data-aos="fade-left" data-aos-duration="2000">
        <h1 className='web-head-3'>Integration with Third-Party APIs</h1>
        <div className='web-detail-4'>
        <p className='web-para-3'>
        Enhance the functionality of your web app by seamlessly integrating with third-party APIs.
Whether it's payment gateways, social media platforms, or other external services, Oakton
ensures smooth and secure interactions.
        </p>
        <div className='image-centre'><img className='web-img' src='/Images/Website/Web10.svg'></img></div>
        </div>
      </div>

      <div className='web-page-3 container' data-aos="slide-down" data-aos-duration="2000">
        <h1 className='web-head-3'>Security and Compliance</h1>
        <div className='web-detail-3'>
        <div className='image-centre'><img className='web-img' src='/Images/Website/Web11.svg'></img></div>
        <p className='web-para-3'>
        Trust is paramount in web app development. Oakton prioritizes security measures to protect
your users' data and ensure compliance with industry standards. Our development practices
adhere to the latest security protocols.
        </p>
        </div>
      </div>

      <div className='web-page-4 container' data-aos="slide-up" data-aos-duration="2000">
        <h1 className='web-head-3'>Ongoing Maintenance and Support</h1>
        <div className='web-detail-4'>
        <p className='web-para-3'>
        Oakton is committed to the long-term success of your web
application. We provide continuous maintenance and support, addressing issues promptly,
implementing updates, and ensuring your web app remains secure and efficient.
Transform your digital presence with Oakton Technologies. Collaborate with us, and let's create web
applications that redefine user experiences, drive engagement, and propel your business forward.
Contact us today to turn your web app vision into a reality.
        </p>
        <div className='image-centre'><img className='web-img' src='/Images/Website/Web12.svg'></img></div>
        </div>
      </div>
    </div>
  )
}
