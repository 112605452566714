import React from 'react'
import Servicecomponent from '../Servicecomponent/Servicecomponent'



export default function DetailService() {
  return (
    <div>
      <Servicecomponent/>
    </div>
  )
}
