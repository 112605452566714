import React from 'react'
import Carrercomponent from '../Carrercomponent/Carrercomponent'

export default function DetailCarrer() {
  return (
    <div>
      <Carrercomponent/>
    </div>
  )
}
